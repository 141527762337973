html{
  scroll-behavior: smooth !important;
}
body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #59BEF4;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Header */
.nav_link {
  color: #202020;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Nunito Sans', sans-serif !important;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  transition: all 0.5s ease-in-out;
}
.nav_link:active{
  background-color: #2020204d;
}
.nav_link.active,
.sidebar.nav_link.active span {
  background: rgba(89, 190, 244, 0.40);
  font-weight: 800 !important;
}

/* Header */

/* Sidebar */
.sidebar.nav_link {
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
}
.sidebar.nav_link span {
  padding: 10px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #202020;
  font-family: 'Nunito Sans', sans-serif !important;
}

/* Sidebar */

/* Swiper */
.swiper {
  padding-bottom: 5px !important;
  position: static !important;
}

.button {
  position: absolute;
  top: 45%;
  z-index: 4;
  color: #FFF;
  background: #59BEF4;
  border-radius: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

/* Swiper */

/* Media Queries */
@media (min-width: 900px) {
  .css-1kcxon2-MuiToolbar-root {
    padding: 20px 28px !important;
    gap: 50px;
  }
}

/* Media Queries */